.login-page{
    position: fixed;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #010518;
}

.login-page .form-container{
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.login-page .form-container h3{
    color: #FFFFFF;
    text-align: center;
}

.login-page .form-container form{
    padding: 30px 50px;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.login-page .form-container form .label{
    color: #010518 !important;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 18px;
    text-align: left;
}

.login-page .form-container form input{
    width: 100%;
    background-color:#F3F6F9;
    border-radius: 4px;
    border: 0;
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    padding: 10px;
    margin-bottom: 0;
    size: 30;
}
.login-page .form-container form input[type=email]{
    width: 95%;
}
.login-page .form-container form input[type=password]{
    width: 95%;
}

.login-page .form-container form:focus-within{
    border: 0 !important;
}

.login-page .form-container form input[type=submit]{
    background-color: #010518;
    border-radius: 2px;
    margin-top: 30px;
    color: white;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.login-page .form-container form h5{
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: #232F3E;
}
.login-page .form-container a{
    font-size: 12px;
    line-height: 20px;
    color: #232F3E;
}
.login-page .form-container form .forgotPass{
    text-align: left;
    font-size: 12px;
}

input::placeholder{
    color: #d9d9d9;
}