.drop {
    display: flex;
    flex-flow: column;
    cursor: pointer;
    text-align: center;
    text-transform: capitalize;
}

.drop p {
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
}

.drop .dropdown {
    position: relative;
    display: inline-block;
}

.drop .dropdown-content {
    display: none;
    position: absolute;
    padding-top: 0;
    background-color: transparent;
    z-index: 1;
}

.drop .dropdown-content p {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    text-align: left;
    display: block;
}

.drop .dropdown-content p:hover {
    background-color: #202124;
}

.drop .dropdown:hover .dropdown-content {
    display: block;
}
.react-datepicker__day--in-range{
    background-color: #353F4D;
}

.react-datepicker__day:hover{
    background-color: #353F4D;
}

.drop .dropdown-content:hover {
    display: block;
}

.drop .mobile-dropdown:focus .dropdown-content {
    display: block;
}

/* new */

.drop .titlesm{
    background-color: #2E3344;
    border-radius: 5px;
    height: 30px;
    min-width: 120px;
}

.drop .titlelg{
    background-color: #2E3344;
    border-radius: 5px;
    height: 40px;
    min-width: 160px;
}
.drop .titlesm:hover, .drop .titlelg:hover{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.wrappersm{
    background-color: #2E3344;
    min-width: 120px;
    z-index: 1;
}
.wrapperlg{
    background-color: #2E3344;
    min-width: 160px;
    z-index: 1;
} 

.wrappertable{
    background-color: #2E3344;
    min-width: 160px;
    z-index: 1;
} 
.drop .titletable{
    background-color: #2E3344;
    border-radius: 5px;
    height: 40px;
    min-width: 160px;
}
.drop .titletable:hover{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.react-datepicker__day--disabled{
    color:#404657 !important;
}

.react-datepicker__day{
    /* color:#ffffff; */
}

.react-datepicker__year-read-view--selected-year{
    color: #fff;
}


.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
    background-color: #2a3f5b;
    color: #fff;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #fff;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day-name{
    color: #FFFFFF;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #fff;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #182434;
    color: #000;
    border: 0px;
    border-radius: 0;
    display: inline-block;
    position: relative;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: #182434;
    border-bottom: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
    position: relative;
  
  }
@media (max-width: "1000px"){
    .wrappertable{
        background-color: #2E3344;
        min-width: 100px;
        z-index: 1;
    } 
    .drop .titletable{
        background-color: #2E3344; 
        border-radius: 5px;
        height: 40px;
        min-width: 50px;
    }
    .drop .titletable:hover{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

/* arrow */
