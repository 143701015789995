@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #010518;
  color: white;
}
@font-face {
  font-family: 'SKF';
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinBold.ttf');
}

@font-face {
  font-family: 'SKF';
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinDemiBold.ttf');
}

@font-face {
  font-family: 'SKF';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinMedium.ttf');
}

@font-face {
  font-family: 'SKF';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinLight.ttf');
}

@font-face {
  font-family: 'SKF';
  font-weight: 100;
  font-style: normal;
  src: url('./assets/fonts/SKFChevinOT-Thin.otf');
}

html, body {
  width: 100%;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  font-family: 'SKF';
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* WRAPPERS */

.section-wrapper {
  max-width: 1400px;
  margin: auto;
  overflow: hidden;
  padding: 10px 30px;
}

.section-wrapper-small{
  max-width: 970px;
  margin: auto;
  padding: 10px 30px;
}

.flex-row {
  display: flex;
  flex-flow: row wrap;
}

.flex-row-nowrap {
  display: flex;
  flex-flow: row nowrap;
}

.flex-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.align-center{
  align-items: center;
}

.align-bottom{
  align-items: flex-end;
}

.flex-one{
  flex: 1;
}

.page-wrapper-grey, .page-wrapper-white{
  padding-bottom: 100px;
}

.page-wrapper-grey{
  background-color: #F1F1F1;
}

.page-wrapper-white{
  background-color: white;
}

/* WRAPPERS-END */

/* MARGIN */

.margin-auto {
  margin: auto;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

/* MARGIN-END */

/* PADDING */

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

/* PADDING-END */

/* WIDTH */

.one-quarter {
  width: 25%;
}

.one-third {
  width: 33%;
}

.one-half {
  width: 50%;
}

.two-thirds {
  width: 66%;
}

.three-quarters {
  width: 75%;
}

.one-whole {
  width: 100%;
}

/* WIDTH-END */

/* FONTS */
.uppercase{
  text-transform: uppercase;
}

.underline{
  text-decoration: underline;
}


/* FONTS-END */

.reset-spacing {
  margin: 0 !important;
  padding: 0 !important;
}

.reset{
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  text-decoration: none;
}

.action-button{
  width: 100%;
  height: 45px;
  background-color:  #2B66B0;
  color: white;
  font-weight: 600;
  font-size: 16px;
  border: 0;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
}

button{
  cursor: pointer;
}

.disabled{
  background-color: #C6C6C6 !important;
  color: #5f5f5f !important;
  cursor:default;
}

.error{
  color: #B12704 !important;
}

.green{
  color: #1C8870;
}

.rotate{
  transform: rotate(90deg);
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.temp-border-red{
  border: 1px solid red;
}

.pointer{
  cursor: pointer;
}

.hide{
 display: none !important;   
}

@media only screen and (max-width: 900px) {
  h3 {
    font-size: 20px !important;
  }
  h2 {
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 425px) {

  .section-wrapper{
    padding: 10px;
  }

}

.error-banner{
  background-color: #440C19;
  padding: 10px;
  border-radius: 8px;
  color: white;
}

/* LABEL STYLE */
.label{
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: .4em .6em .3em;
  font-weight: 700;
  line-height: 1;
  color: #fff !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  border: 1px solid transparent;
}

.label-special {
  background-color: #FFFFFF;
  color: #142F4D !important;
  border-color: #142F4D !important;
}

.label-default {
  background-color: #777;
}

.label-primary {
  background-color: #337ab7;
}

.label-success {
  background-color: #3f973f;
}

.label-info {
  background-color: #d88200;
}

.label-warning {
  background-color: #142F4D;
}

.label-danger {
  background-color: #d9534f;
}
::placeholder {
  color: white;
}