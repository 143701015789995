.wrapper{
    border: 1px solid grey;
  }

  .rdw-dropdown-optionwrapper{
    color:black
  }

  .rdw-dropdown-selectedtext{
    color:black;
  }