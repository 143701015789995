.min-width-limiter{
    min-width: 726px;
}

.get-nf {
    padding: 12px 25px;
    background-color: #232F3E;
    color: #FFFFFF;
    border: 0;
    border-radius: 3px;
    height: 38px;
    margin-top: 10px;
    margin-left: 10px;
}