.api-token-page{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.api-token-page .force-color{
    background-color: rgb(25, 40, 80);
}


.api-token-page h1{
    font-size: 30px;
}