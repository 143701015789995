.sidebar{
    border-right: solid 1px #333;
    box-shadow: 2px 0 5px -2px black;
    background-color: #131930;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 250px;
}

.sidebar .content{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}
.sidebar .content .company{
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: 10%;
    margin-top: 10%;
    width: 100%;
}
.sidebar .dashboard{
    padding-bottom: 5%;
    padding-top: 5%;  
    margin-left: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.sidebar .items{
    margin-left: 20%;
}

.max-widht-18{
    max-width: 18%;
}

.sidebar .sidebar-item{
    padding-bottom: 5%;
    padding-top: 5%;
}
.sidebar .sidebar-subitem{
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: 20%;
    width: 100%;
}

.w-70 {
    width: 17rem;
}

.faded{
    color: #ffffff42 !important;
}