.home .loader-wrapper{
  width: 80px !important;
  height: 40px;
}

.bg-red {
  background-color: #81263B;
}

/* .swal2-input{
  width: 80% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
} */