 .capgemini-task-page .tbl-column {
    min-width: 110px;
    display: inline-block;
}
.capgemini-task-page .sorting-section p {
    font-size: 16px;
    font-weight: 600;
}

.col-width-force{
    min-width: 135px;
    max-width: 135px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.col-width-force-2{
    min-width: 177px;
    max-width: 177px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.capgemini-task-page .custom-color{
   background-color: #010518;
}

.capgemini-swal .swal2-popup{
    background: #1E2B3C;
    color: white;
    border-radius: 8px;
}

.capgemini-swal .swal2-html-container{
    margin-top: 0 !important;
}

.capgemini-swal .swal2-popup hr{
    background: white;
    color: white;
}
.capgemini-swal .swal2-title{
    text-align: left;
    font-weight: 300;
    margin-bottom: 10px;
}

/* .capgemini-swal .swal2-confirm, .swal2-cancel{
    width: 90px;
    margin-left: 20px;
    margin-right: 20px;
} */

.capgemini-task-page .table-height-limiter{
    max-height: 520px;
}

.capgemini-task-page .sorting-section .mobile-filter-button{
    cursor: pointer;
}

.capgemini-task-page .sorting-section .mobile-filter-button img{
    max-height: 15px;
    margin-right: 5px;
}

.capgemini-task-page .sorting-section .sorting{
    font-weight: 300;
}

.capgemini-task-page .sorting select, .capgemini-task-page .sorting select > * {
    font-size: 16px;
    font-weight: 300 !important;
    border: 0;
}

.capgemini-task-page .sorting select:focus{
    outline: 0;
}

.capgemini-task-page .relative{
    position: relative;
}

.capgemini-task-page .filters-section {
    position: relative;
    min-width: 280px;
    max-width: 280px;
    border-right: 2px solid #D8D8D8;
    padding: 30px;
}

.capgemini-task-page .filters-section .close{
    position: absolute;
    top: 25px;
    right: 25px;
    width: 25px;
    cursor: pointer;
}

.capgemini-task-page .filters-section .active {
    font-weight: 500 !important;
}

.capgemini-task-page .filters-section .categories {
    margin-left: 20px;
}

.capgemini-task-page .filters-section .categories h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 3px;
    cursor: pointer;
}

.capgemini-task-page .filters-section .categories p {
    font-size: 15px;
    font-weight: 300;
    margin: 4px 0 3px 3px;
    cursor: pointer;
}

.capgemini-task-page .filters-section .prices {
    margin-left: 20px;
}

.capgemini-task-page .filters-section .prices p {
    font-size: 16px;
    margin-bottom: 5px;
    cursor: pointer;
}

.capgemini-task-page .filters-section .manual-price-box .range-box {
    padding: 10px;
    width: 32%;
    border: 1px solid #232F3E;
    border-radius: 4px;
}

.capgemini-task-page .filters-section .manual-price-box .range-box p {
    flex: 0;
    font-size: 14px;
    margin-right: 2px;
    position: relative !important;
    top: 5px !important;
}

.capgemini-task-page .filters-section .manual-price-box .range-box input {
    width: 100%;
    border: 0;
    font-size: 14px;
}

.capgemini-task-page .filters-section .manual-price-box .range-box input:focus {
    outline: 0;
    border: 0;
}

.capgemini-task-page .filters-section .manual-price-box button {
    background-color: #232F3E;
}

.capgemini-task-page  {
    position: relative;
    padding: 45px;
    padding-bottom: 20px;
}

.capgemini-task-page  h3 {
    font-size: 20px;
    font-weight: 300;
}

.capgemini-task-page  .page-selector {
    width: fit-content;
    margin: auto;
    border-radius: 4px;
    border: 1px solid #979797;
    margin-top: 20px;
}

.capgemini-task-page  .page-selector .control-buttons {
    margin: 12px 0;
    padding: 4px 12px;
    cursor: pointer;
    min-width: 40px;
}

.capgemini-task-page  .page-selector .control-buttons:first-of-type {
    border-right: 1px solid #D8D8D8;
}

.capgemini-task-page  .page-selector .control-buttons:last-of-type {
    border-left: 1px solid #D8D8D8;
}

.capgemini-task-page  .page-selector .control-buttons img {
    height: 12px;
    margin: auto 7px;
}

.capgemini-task-page  .page-selector .control-buttons p {
    font-size: 14px;
}

.capgemini-task-page  .page-selector .pages-section {
    width: fit-content;
}

.capgemini-task-page  .page-selector .page-num {
    width: 1vw;
    min-width: 35px;
    max-width: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #979797;
}

.capgemini-task-page  .page-selector .active {
    color:white;
}


.sub-cat{
    font-size: 20px !important;
}

.av-check{
    margin-right: 10px;
}

@media only screen and (max-width: 768px) {
    .capgemini-task-page .filters-section .close{
        
    }
    .capgemini-task-page .filters-section {
        display: none;
        position: fixed;
        max-width: none;
        overflow-y: auto;
        min-width: 200px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: white;
        padding: 70px;
    }
}

@media only screen and (max-width: 425px) {
    .capgemini-task-page  {
        padding: 10px;
    }
}

.min-width-limiter{
    min-width: 100%;
    max-height: 668px;
}