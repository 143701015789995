.wholeField {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.arrow-width {
    width: 4%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.arrow-width svg {
    margin: 0;
    padding: 0;
    position: relative;
    right: 10px;
    bottom: 5px;
}

.line-width {
    display: flex;
    flex-direction: row;
    width: 96%;
    gap: 15px
}

.API {
    width: 49%;
}

.API p {
    font-size: 22px;
    padding: 2%;
    text-align: center;
    margin-bottom: 15px;
}

.API-global {
    border-right: 1px dashed white
}

.API .table {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.API .table .tableHeader {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1%;
    margin-bottom: 10px
}

.API .table .tableBody {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.API .table .tableRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4%;
    background-color: #232F3E;
    border-radius: 8px;
    height: 45px;
    cursor: pointer;
}

.API .table .special {
    background-color: #465363;
}

.API .table .snowflake {
    width: 60%;
}

@media (max-width: 1025px) {

    .wholeField {
        flex-direction: column-reverse;
    }

    .line-width {
        flex-direction: column;
    }

    .arrow-width {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

    }
    .arrow-width svg {
        bottom: 0;
        right: 0;
    }

    .API {
        width: 100%;
        margin: 15px 0;
    }

    .API .table .tableHeader {
        width: 100%
    }

    .API .table .tableBody {
        width: 100%;
    }

    .API-global {
        border-right: none
    }
}