.max-h-128{
    max-height: 670px;
}


.registration-page  .page-selector {
    width: fit-content;
    margin: auto;
    border-radius: 4px;
    border: 1px solid #979797;
    margin-top: 20px;
}

.registration-page  .page-selector .control-buttons {
    margin: 12px 0;
    padding: 4px 12px;
    cursor: pointer;
    min-width: 40px;
}

.registration-page  .page-selector .control-buttons:first-of-type {
    border-right: 1px solid #D8D8D8;
}

.registration-page  .page-selector .control-buttons:last-of-type {
    border-left: 1px solid #D8D8D8;
}

.registration-page  .page-selector .control-buttons img {
    height: 12px;
    margin: auto 7px;
}

.registration-page  .page-selector .control-buttons p {
    font-size: 14px;
}

.registration-page  .page-selector .pages-section {
    width: fit-content;
}

.registration-page  .page-selector .page-num {
    width: 1vw;
    min-width: 35px;
    max-width: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #979797;;
}

.registration-page  .page-selector .active {
    color:white;
}

.sub-cat{
    font-size: 20px !important;
}

.av-check{
    margin-right: 10px;
}

@media only screen and (max-width: 768px) {
    .registration-page .filters-section .close{
        
    }
    .registration-page .filters-section {
        display: none;
        position: fixed;
        max-width: none;
        overflow-y: auto;
        min-width: 200px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: white;
        padding: 70px;
    }
}

@media only screen and (max-width: 425px) {
    .registration-page  {
        padding: 10px;
    }
}

.min-width-limiter{
    min-width: 726px;
    max-height: 668px;
}